/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import useUser from '@bottlebooks/gatsby-plugin-firebase-auth/src/useUser';
import useSiteConfig from '@bottlebooks/gatsby-theme-event/src/components/useSiteConfig';
import React, { useEffect } from 'react';
import { jsx } from 'theme-ui';

const SectorGlobalContext = React.createContext(
  /** @type {[boolean, React.Dispatch<React.SetStateAction<boolean>>]} */ undefined
);

export function SectorGlobalProvider({ children }) {
  return (
    <SectorGlobalContext.Provider>{children}</SectorGlobalContext.Provider>
  );
}

/**
 * This hook exposes the core authentication API and context.
 * It should be used wherever authentication status is needed.
 * @returns
 */
export default function useSectorGlobal() {
  const [user] = useUser();
  const { bottlebooksApiRoot } = useSiteConfig();
  const [sectorGlobalLink, setSectorGlobalLink] = React.useState<
    string | null
  >();
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    async function getExternalUrl() {
      setLoading(true);
      const uid = user?.uid;
      const token = await user?.getIdToken();
      const response = await fetch(
        `${bottlebooksApiRoot}/pub/next/londonwinefair/externalUrl/${token}/${uid}`
      );
      const data = await response.json();
      if (data.externalUrl) setSectorGlobalLink(data.externalUrl);
      // else do nothing
      // We only want to load one time.
      // setLoading(false);
    }
    if (!user) return;
    if (loading) return;
    if (sectorGlobalLink) return;
    getExternalUrl();
  }, [
    user,
    sectorGlobalLink,
    setSectorGlobalLink,
    bottlebooksApiRoot,
    loading,
    setLoading,
  ]);
  return {
    getExternalUrl({ exhibitorId }: { exhibitorId: string }) {
      if (!sectorGlobalLink)
        return `https://london-wine-fair.eventware.com/london-wine-fair-2024/companies/find/${exhibitorId} `;
      else
        return `${sectorGlobalLink}&passthrough=/london-wine-fair-2024/companies/find/${exhibitorId}`;
    },
  };
}
